import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import StructuredTextRenderer from "components/StructuredTextRenderer/StructuredTextRenderer";
import { Section } from "@soundtrackyourbrand/ui/src/Section";

export default function NotFoundPage() {
  return (
    <StaticQuery
      query={graphql`
        query NotFoundPageQuery {
          datoCmsNotFound {
            content {
              value
              blocks {
                __typename
                ...Link
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section>
          <StructuredTextRenderer
            data={data.datoCmsNotFound.content}
            isParagraphClassName="mb-5 subtle"
          />
        </Section>
      )}
    />
  );
}